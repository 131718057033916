import { defineStore } from 'pinia'
import { fetchAuthStatus, logout } from '@/services/auth'
import log from '@/services/logConfig';

export const useAuthStore = defineStore('auth', {
  state: () => {
    log.debug('Initializing auth store with default state');
    return {
      isAuthenticated: false,
      isAuthReady: false,
      displayName: null,
      userkey: null,
      roles: [],
    }
  },
  getters: {
    getUserkey: (state) => {
      log.debug('Getting userkey:', state.userkey);
      return state.userkey;
    }
  },
  actions: {
    async checkAuth() {
      log.debug('Checking authentication status...');
      try {
        const status = await fetchAuthStatus()
        log.info('Auth status received:', status);
        
        log.debug('Previous auth state:', {
          isAuthenticated: this.isAuthenticated,
          displayName: this.displayName,
          userkey: this.userkey,
          roles: this.roles,
        });

        this.isAuthenticated = status.isAuthenticated
        this.displayName = status.displayName
        this.userkey = status.userkey
        this.roles = status.roles || []
        
        log.info('Authentication state updated successfully');
        log.debug('New auth state:', {
          isAuthenticated: this.isAuthenticated,
          displayName: this.displayName,
          userkey: this.userkey,
          roles: this.roles,
        });

      } catch (error) {
        log.error('Failed to check auth status:', error);
        log.debug('Error details:', {
          message: error.message,
          stack: error.stack,
          response: error.response?.data
        });
        this.resetAuthState()
      } finally {
        this.isAuthReady = true;
        log.debug('AuthStore: Auth ready state updated', {
          isAuthenticated: this.isAuthenticated,
          isAuthReady: this.isAuthReady
        });
      }
    },
    async logoutUser() {
      log.debug('Initiating logout process...');
      log.debug('Current state before logout:', {
        isAuthenticated: this.isAuthenticated,
        displayName: this.displayName,
        userkey: this.userkey,
        roles: this.roles,
      });

      try {
        await logout()
        log.info('Logout API call successful');
        this.resetAuthState()
        log.info('Logout process completed successfully');
      } catch (error) {
        log.error('Logout failed:', error);
        log.debug('Logout error details:', {
          message: error.message,
          stack: error.stack,
          response: error.response?.data
        });
        throw error
      }
    },
    resetAuthState() {
      log.debug('Resetting auth state to defaults');
      log.debug('Current state before reset:', {
        isAuthenticated: this.isAuthenticated,
        displayName: this.displayName,
        userkey: this.userkey,
        roles: this.roles,
      });

      this.isAuthenticated = false
      this.displayName = null
      this.userkey = null
      this.roles = []

      log.debug('Auth state after reset:', {
        isAuthenticated: this.isAuthenticated,
        displayName: this.displayName,
        userkey: this.userkey,
        roles: this.roles,
      });
      log.info('Auth state reset complete');
    }    
  }
})