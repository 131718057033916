<template>
    <nav v-if="authStore.isAuthReady && !authStore.isAuthenticated" class="horizontal-nav">
        <ul>
            <li><a href="/docs/about">About</a></li>
            <li><a href="/docs/terms">Terms</a></li>
            <li><a href="/docs/privacy">Privacy</a></li>
            <li><a href="/docs/faq">FAQ</a></li>
            <li><a href="/docs/contact">Contact</a></li>
        </ul>
    </nav>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import log from '@/services/logConfig';

const route = useRoute();
const authStore = useAuthStore();

log.debug('BottomNavigation: Component initialized', {
    isAuthenticated: authStore.isAuthenticated,
    isAuthReady: authStore.isAuthReady,
    currentPath: route.path
});
</script>

<style scoped>
/* New styles for horizontal navigation */
.horizontal-nav {
    margin: 20px;
}

.horizontal-nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.horizontal-nav li {
    margin: 0 15px;
}

.horizontal-nav a {
    color: #4a4a4a;
    text-decoration: none;
    font-size: 0.9rem;
}

.horizontal-nav a:hover {
    color: #007bff;
    text-decoration: underline;
}
</style>