import { createRouter, createWebHistory } from 'vue-router'
import { fetchSettings } from '@/services/api'
import { fetchAuthStatus } from '@/services/auth'
import log from 'loglevel';

const APP_NAME = 'Curalist'

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

// Add debug log for initialization
log.debug('Initializing router configuration');

// Lazy load all route components
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue'),
    beforeEnter: async (to, from, next) => {
      log.debug(`Home route guard triggered. From: ${from.path}, To: ${to.path}`);
      try {
        const redirectRoute = await determineRedirectRoute()
        if (redirectRoute) {
          log.debug(`Home redirect determined: ${JSON.stringify(redirectRoute)}`);
          next(redirectRoute)
        } else {
          log.debug("No redirect needed, proceeding to Home page");
          next()
        }
      } catch (error) {
        log.error('Error in home route navigation guard:', error)
        next(error)
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: { title: 'Not Found' }
  },
  { 
    path: '/add', 
    component: () => import('@/views/AddNewItemView.vue'), 
    meta: { title: 'Add', requiresAuth: true } 
  },
  { 
    path: '/admin', 
    name: 'Admin', 
    component: () => import('@/views/AdminView.vue'), 
    meta: { title: 'Administration', requiresAuth: true, requiresAdmin: true } 
  },
  { 
    path: '/admin/feed', 
    component: () => import('@/views/Admin/FeedView.vue'), 
    meta: { title: 'Feed', requiresAuth: true, requiresAdmin: true } 
  },
  { 
    path: '/admin/lists', 
    component: () => import('@/views/Admin/ListsView.vue'), 
    meta: { title: 'Lists', requiresAuth: true, requiresAdmin: true } 
  },
  { 
    path: '/admin/users', 
    component: () => import('@/views/Admin/UsersView.vue'), 
    meta: { title: 'Users', requiresAuth: true, requiresAdmin: true } 
  },
  { 
    path: '/feed', 
    name: 'Feed', 
    component: () => import('@/views/FeedScrollView.vue'), 
    meta: { title: 'Discover' } 
  },
  { 
    path: '/inbox', 
    name: 'Inbox', 
    component: () => import('@/views/InboxView.vue'), 
    meta: { title: 'Inbox' } 
  },
  { 
    path: '/lists', 
    name: 'Lists', 
    component: () => import('@/views/ListsListView.vue'), 
    meta: { title: 'Lists', requiresAuth: true } 
  },
  { 
    path: '/users/:userkey/lists/:listkey', 
    name: 'List', 
    component: () => import('@/views/ListView.vue'), 
    meta: { title: 'List' } 
  },
  { 
    path: '/messages', 
    name: 'Messages', 
    component: () => import('@/views/MessagesListView.vue'), 
    meta: { title: 'Messages', requiresAuth: true } 
  },
  { 
    path: '/users/:userkey', 
    name: 'UserProfile', 
    component: () => import('@/views/UserProfileView.vue'),
    props: true, 
    meta: { title: 'User Profile' } 
  },
  { 
    path: '/profile', 
    name: 'MyUserProfile', 
    component: () => import('@/views/UserProfileView.vue'), 
    meta: { title: 'Your profile', requiresAuth: true } 
  },
  { 
    path: '/search', 
    name: 'Search', 
    component: () => import('@/views/SearchView.vue'), 
    meta: { title: 'Search' } 
  },
  { 
    path: '/settings', 
    name: 'Settings', 
    component: () => import('@/views/SettingsView.vue'), 
    meta: { title: 'User Settings', requiresAuth: true } 
  },
  { 
    path: '/users', 
    name: 'Users', 
    component: () => import('@/views/UsersListView.vue'), 
    meta: { title: 'Curators' } 
  },
  { 
    path: '/login', 
    component: () => import('@/views/LoginPage.vue'), 
    name: 'Login', 
    meta: { title: 'Login' } 
  },
  { 
    path: '/register', 
    component: () => import('@/views/LoginPage.vue'), 
    name: 'Register', 
    meta: { title: 'Register' } 
  },
  {
    path: '/docs/:docName',
    name: 'Doc',
    component: () => import('@/views/DocumentView.vue'),
    props: true
  },
  {
    path: '/checkurl',
    name: 'checkUrl',
    component: () => import('@/views/CheckUrlView.vue')
  },
  {
    path: "/signalr",
    name: "SignalR",
    component: () => import('@/views/SignalRTest.vue')
  },
  { 
    path: '/forgot-password', 
    component: () => import('@/views/ForgotPassword.vue'), 
    name: 'ForgotPassword', 
    meta: { title: 'Reset Password' } 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

async function determineRedirectRoute() {
  log.debug('Determining redirect route');
  let homeComponent = 'Home';
  let title = 'Home';

  // Check if user is authenticated
  const authStatus = await fetchAuthStatus();
  log.debug('Auth status for redirect determination:', authStatus);

  if (authStatus.isAuthenticated) {
    try {
      log.debug('Fetching user settings for redirect');
      const settings = await fetchSettings();
      log.debug('User settings received:', settings);

      if (settings?.preferences?.startPage) {
        log.debug(`Start page preference found: ${settings.preferences.startPage}`);
        switch (settings.preferences.startPage) {
          case 'home':
            homeComponent = 'Home';
            title = 'Home';
            break;
          case 'feed':
            homeComponent = 'Feed';
            title = 'Feed';
            break;
          case 'my-lists':
            homeComponent = 'Lists';
            title = 'My Lists';
            break;
          default:
            // If startPage is not 'feed' or 'mylists', we'll stay on the Home page
            return null;
        }
      } else {
        log.warn('No start page preference found in settings');
        return null;
      }
    } catch (error) {
      log.error('Failed to fetch settings for redirect:', error);
      return null;
    }
  } else {
    log.debug('User not authenticated, no redirect needed');
  }
  
  log.debug(`Redirect determination complete. Component: ${homeComponent}, Title: ${title}`);
  return homeComponent !== 'Home' ? { name: homeComponent, title: title } : null;
}

router.beforeEach(async (to, from, next) => {
  log.debug(`Global navigation guard triggered. From: ${from.path}, To: ${to.path}`);
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    log.debug('Route requires authentication, checking status');
    const authStatus = await fetchAuthStatus();
    log.debug('Authentication status:', authStatus);

    if (!authStatus.isAuthenticated) {
      log.warn(`Authentication required for ${to.path}. Redirecting to login`);
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else {
      log.debug('Authentication check passed');
      next();
    }
  } else {
    log.debug('No authentication required for this route');
    next();
  }
});

router.afterEach((to) => {
  // Use the route's meta title, or fall back to the route name
  log.debug(`afterEach to ${to.path} started`)
  const pageTitle = to.meta.title || to.name || 'Untitled Page'
  document.title = `${pageTitle} - ${APP_NAME}`
  log.debug(`Navigation completed. Page title set to: ${document.title}`)
})

export default router