<template>
  <span class="icon" v-if="svgIcon" v-html="svgIcon"
    :style="{ color: color, width: iconSize + 'px', height: iconSize + 'px' }"></span>
  <span v-else>Icon not found: {{ icon }}</span>
</template>

<script setup>
import { computed } from 'vue';
import feather from 'feather-icons';
import log from '@/services/logConfig';

const props = defineProps({
  icon: {
    type: String,
    required: true
  },
  size: {
    type: [Number, String],
    default: 24
  },
  color: {
    type: String,
    default: 'currentColor'
  }
});

const iconSize = computed(() => {
  return typeof props.size === 'string' ? parseInt(props.size, 10) : props.size;
});

const svgIcon = computed(() => {
  //log.debug('Trying to render icon:', props.icon);

  if (feather.icons[props.icon]) {
    return feather.icons[props.icon].toSvg({
      width: iconSize.value,
      height: iconSize.value,
      color: props.color
    });
  } else {
    log.error(`Icon not found: ${props.icon}`);
    return null;
  }
});
</script>