import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import FeatherIcon from './components/FeatherIcon.vue'
import router from './router'
import './assets/global.css'  // Import the global CSS
import './assets/dynamic.css'  // Import the global CSS
import './assets/darkTheme.css'  // Import the global CSS

// Enable performance measuring in development
if (process.env.NODE_ENV !== 'production') {
  const app = createApp(App)
  app.config.performance = true
}

createApp(App)
  .component('FeatherIcon', FeatherIcon)
  .use(createPinia())
  .use(router)
  .mount('#app')